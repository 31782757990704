.social-media {
  margin-top: 40px;
  text-align: start;
  font-family: "Lexend", sans-serif;
}

.social-media h4 {
  font-weight: bold;
  font-size: 2rem;
}

.logo {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.logo img {
  width: 32px;
  margin-right: 20px;
}

.logo p {
  margin: 0;
  font-size: 1.2rem;
}

@media only screen and (max-height: 768px) {
  .social-media h4 {
    font-size: 1.5rem;
  }
  .social-media {
    margin-top: 15px;
  }
  .logo {
    margin: 10px 0;
  }
  .logo img {
    width: 25px;
    margin-right: 15px;
  }
  .logo p {
    font-size: 1.1rem;
  }
}
