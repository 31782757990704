/* <reset-style> ============================ */
.main__action {
  width: 50px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

a {
  text-decoration: none;
}
/* <main-style> ============================ */
.main__scroll-text {
  color: rgba(28, 28, 30, 1);
}

.main__action:hover .main__scroll-box {
  animation: scroll-down 3s infinite;
}

/* Animate scroll icon */
@keyframes scroll-down {
  0%,
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  35% {
    transform: translateY(1rem);
    opacity: 0;
  }

  70% {
    transform: translateY(-1rem);
    opacity: 0;
  }
}
