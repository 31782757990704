.footer {
  font-family: "Lexend", sans-serif;
  border-bottom: 16px solid #d41e3c;
}

.hr {
  border: 0;
  border-top: 3px solid;
  border-color: #1d1d1d;
  opacity: 1;
  margin: 0;
}

.logo {
  width: 200px;
  margin: 40px 0;
}

.dark {
  background-color: #0f0f0f;
}

