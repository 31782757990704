.gachapon {
  padding: 70px 0;
  position: relative;
}

.container {
  height: 100%;
}

.machine-text-container {
  height: 100%;
  padding: 70px 0;
  align-items: center;
}

.dark {
  background-color: #0f0f0f;
  color: white;
}
