.title {
  font-size: 2rem;
  font-weight: bold;
}

.title,
.text {
  color: #1d1d1d;
  font-family: "Lexend", sans-serif;
}

.text {
  font-size: 1.35rem;
}

.title.dark {
  color: white;
}

.title.dark {
  color: white;
}

.text.dark {
  color: #e0e1e6;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.7rem;
    margin: 20px;
  }
  .text {
    font-size: 1rem;
  }
}
