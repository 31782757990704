.nav {
  margin: 0px 0 170px;
}

@media only screen and (max-width: 600px) {
  .nav {
    margin: 0px 0 20px;
  }
}

@media only screen and (min-width: 600px) {
  .nav {
    margin: 0px 0 30px;
  }
}

@media only screen and (min-width: 992px) {
  .nav {
    margin: 0px 0 170px;
  }
}

@media only screen and (min-width: 1200px) {
  .nav {
    margin: 0px 0 100px;
  }
}

@media only screen and (min-width: 1600px) {
  .nav {
    margin: 0px 0 170px;
  }
}
