.gachapon-img {
  width: 65%;
  margin: 0 0 30px;
}

.gachapon-button {
  border: 0;
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .gachapon-button {
    margin-top: 20px;
  }
}

