.cards {
  position: relative;
  height: 100%;
  margin-top: 0px;
}

.card1,
.card2,
.card3 {
  cursor: pointer;
  background-color: white;
}

.dark {
  background-color: #1d1d1d;
}

@media only screen and (max-width: 600px) {
  .cards {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .line1,
  .line2 {
    display: none;
  }

  .cards {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .card1,
  .card2,
  .card3 {
    width: 220px;
    height: 240px;
  }
}

@media only screen and (min-width: 992px) {
  .line1,
  .line2 {
    display: none;
  }

  .cards {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
  }

  .card1,
  .card2,
  .card3 {
    width: 275px;
    height: 340px;
  }
}

@media only screen and (min-width: 1200px) {
  .cards {
    margin-top: 0px;
  }

  .card1,
  .card2,
  .card3 {
    position: absolute;
  }

  .card1 {
    margin: auto;
    left: 40%;
    bottom: 0px;
    width: 180px;
    height: 250px;
  }

  .card2 {
    margin-top: 80px;
    top: -85px;
    left: 70px;
    transform: rotate(-20deg);
    width: 160px;
    height: 230px;
  }

  .card3 {
    margin-top: 80px;
    top: -155px;
    right: 70px;
    transform: rotate(40deg);
    width: 145px;
    height: 220px;
  }

  .line1,
  .line2 {
    display: block;
    border: 0;
    border-top: 2px dashed;
    width: 600px;
    position: absolute;
  }

  .line1 {
    border: 0;
    border-top: 2px dashed;
    transform: rotate(20deg);
    top: 150px;
    left: 20px;
  }

  .line2 {
    transform: rotate(-40deg);
    top: 180px;
    right: 20px;
  }
}

@media only screen and (min-width: 1600px) {
  .card1 {
    bottom: 100px;
    width: 200px;
    height: 280px;
  }
}
