.faq {
  padding: 70px 0;
  position: relative;
  height: 100vh;
}
.faq-cards {
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .faq {
    height: fit-content;
  }
}

@media only screen and (min-width: 600px) {
  .faq {
    height: fit-content;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1600px) {
  .faq {
    height: 100vh;
  }
}

.dark {
  background-color: #0f0f0f;
  color: white;
}
