.title {
  font-family: "Anton", sans-serif;
  font-size: 4rem;
}

.progress {
  background-color: #1d1d1d;
  width: 220px;
  height: 15px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: skew(150deg);
  margin: auto;
}

.inner {
  background-color: #d41e3c;
  height: 100%;
  transform-origin: left;
  transform: skew(-150deg);
  animation: progress 1.8s infinite;
}

@keyframes progress {
  0% {
    transform: scaleX(10%) translateX(-10%);
  }

  100% {
    transform: scaleX(90%) translateX(150%);
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 3rem;
  }
}
