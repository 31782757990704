.mint {
  background-color: white;
  padding: 70px 0;
  height: 100vh;
  position: relative;
}

.card,
.card2 {
  background-color: #1d1d1d;
  color: white;
  border: 2px solid #d41e3c;
  position: relative;
  margin-top: 80px;
  font-family: "Lexend", sans-serif;
}

.card2 {
  display: flex;
  text-align: start;
}

.profile {
  height: 220px;
  width: 420px;
}

.quoate {
  font-size: 1.25rem;
}

.text {
  padding: 30px;
  text-align: start;
}

.img {
  width: 100%;
  height: 380px;
  background-color: #d41e3c;
}

.button {
  background-color: #d41e3c;
  color: white;
  font-size: 2rem;
  margin-left: auto;
}



@media only screen and (max-width: 600px) {


  .mint {
    height: fit-content;
  }

  .card2 {
    margin-top: 20px;
  }

  .quoate {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 600px) {

  .mint {
    height: fit-content;
  }

  .card2 {
    margin-top: 20px;
  }

  .quoate {
    font-size: 1.1rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {


  .card2 {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1200px) {
 

  .card2 {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1600px) {
  .about {
    height: 100vh;
  }
}
@media only screen and (max-height: 768px) {
  .card,
  .card2 {
    margin-top: 60px;
  }
  .img {
    height: 290px;
  }
  .text {
    padding: 10px;
  }
  .button {
    padding: 1px 10px;
    font-size: 1.5rem;
  }

  .profile {
    height: 160px;
    width: 280px;
  }
}

.dark {
  background-color: #0f0f0f;
  color: white;
}
