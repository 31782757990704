.header-text {
  position: relative;
  text-align: start;
}

.header-text h1 {
  font-family: "Anton", sans-serif;
  font-size: 5rem;
}

.header-text p {
  margin: 80px 0 140px;
  font-family: "Lexend", sans-serif;
  font-size: 1.25em;
}

.buttons {
  display: flex;
  justify-content: start;
}

.button {
  background-color: #d41e3c;
  color: white;
}

.black-button {
  background-color: #1d1d1d;
  color: white;
}

@media only screen and (max-width: 600px) {
  .header-text {
    text-align: center;
  }
  .header-text h1 {
    font-size: 2.62rem;
  }
  .header-text p {
    margin: 40px 0 60px;
    font-size: 1em;
  }
  .buttons {
    display: block;
    margin-bottom: 10px;
  }

  .buttons button {
    margin: 10px auto;
    width: 180px;
  }
}
@media only screen and (min-width: 600px) {
  .header-text {
    text-align: center;
  }
  .header-text p {
    margin: 80px 0 120px;
    font-size: 1.15em;
  }
  .buttons {
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) {
  .header-text {
    text-align: center;
  }

  .header-text h1 {
    font-size: 5rem;
  }

  .header-text p {
    margin: 80px 0 140px;
    font-size: 1.25rem;
  }
  .buttons {
    justify-content: center;
  }
}

@media only screen and (min-width: 1200px) {
  .header-text {
    text-align: start;
  }
  .header-text h1 {
    font-size: 4rem;
  }
  .header-text p {
    margin: 50px 0 70px;
    font-size: 1.15rem;
  }
  .buttons {
    justify-content: start;
  }
}

@media only screen and (min-width: 1600px) {
  .header-text {
    text-align: start;
  }
  .header-text h1 {
    font-size: 5rem;
  }
  .header-text p {
    margin: 80px 0 140px;
    font-size: 1.25rem;
  }
  .buttons {
    justify-content: start;
  }
}

p.dark {
  color: #e0e1e6;
}
