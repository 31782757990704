.icon {
  width: 28px;
}

.icon:hover {
  filter: invert(18%) sepia(95%) saturate(2549%) hue-rotate(335deg)
    brightness(96%) contrast(98%);
  animation: spin 0.3s forwards;
}

.dark:hover {
  filter: invert(78%) sepia(56%) saturate(7281%) hue-rotate(343deg)
    brightness(100%) contrast(97%);
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}
