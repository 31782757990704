.section {
  margin: 80px 0 0;
}

.text {
  text-align: start;
  font-family: "Lexend", sans-serif;
}

.text h3 {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 40px;
}

.text p {
  font-size: 1.25rem;
}

.text ul li {
  font-size: 1.15rem;
}

.img {
  width: 164px;
  margin: auto;
  margin: 5px;
  border-radius: 7px;
}

.button {
  background-color: #d41e3c;
  color: white;
  font-size: 2rem;
  margin-left: auto;
}

.imgs-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 360px) {
  .img {
    width: 120px;
  }

  .button {
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .text {
    text-align: center;
  }
  .button {
    margin: auto;
  }

  .text ul li {
    text-align: start;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  .text {
    text-align: center;
  }
  .button {
    margin: auto;
  }
  .text ul li {
    text-align: start;
  }
}

@media only screen and (min-width: 992px) {
  .text {
    text-align: start;
  }
  .button {
    margin: 0;
    margin-left: auto;
  }
  .img {
    width: 134px;
    margin: auto;
    margin: 5px;
    border-radius: 7px;
  }
}

@media only screen and (min-width: 1200px) {
  .text {
    text-align: start;
  }
  .button {
    margin: 0;
    margin-left: auto;
  }
  .img {
    width: 164px;
    margin: auto;
    margin: 5px;
    border-radius: 7px;
  }
}

@media only screen and (max-height: 768px) {
  .section {
    margin: 70px 0 0;
  }
  .text h3 {
    font-size: 1.5rem;
  }
  .text p {
    font-size: 1.05rem;
  }
  .img {
    width: 110px;
  }
}

.dark {
  color: #e0e1e6;
}
