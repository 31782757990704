#link {
  color: #1d1d1d;
}

#dark-link {
  color: white;
}

#link:hover,
#dark-link:hover {
  color: #d41e3c;
}
#link:last-child,
#dark-link:last-child {
  margin-right: 0;
}

#link:after,
#dark-link:after {
  content: "";
  display: block;
  margin: auto;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}
#link:hover:after,
#dark-link:hover:after {
  width: 100%;
  background: #d41e3c;
}

@media only screen and (max-width: 600px) {
  #link,
  #dark-link {
    font-size: 1rem;
  }
}
