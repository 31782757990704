.progress-bg {
  background-color: #1d1d1d;
  color: white;
  margin-top: 20px;
  border: 2px solid #d41e3c;
  padding: 20px;
  text-align: start;
  font-family: "Lexend", sans-serif;
}

.progress {
  width: 100%;
  height: 20px;
  background-color: #d41e3c;
  border-radius: 7px;
  color: white;
  text-align: center;
}

@media only screen and (max-height: 768px) {
  .progress-bg {
    margin-top: 15px;
    padding: 10px;
  }
}
