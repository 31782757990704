.card {
  margin-top: 10px;
  text-align: start;
  background-color: white;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.title {
  display: flex;
  justify-content: space-between;
}

.title h2 {
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0;
  font-family: "Lexend", sans-serif;
}

.title button {
  border: 0;
  padding: 0;
  background-color: transparent;
}

.title i {
  font-size: 1.3rem;
  color: #d41e3c;
  cursor: pointer;
}

.content {
  background-color: white;
  margin-top: 10px;
  text-align: start;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  z-index: 2;
}

.content p {
  font-size: 1.1rem;
  margin: 0;
  font-family: "Lexend", sans-serif;
}

/*<p>{props.text}</p>*/

.card.dark {
  background-color: #1d1d1d;
}

.content.dark {
  background-color: #1d1d1d;
  box-shadow: none;
  color: #e0e1e6;
}
