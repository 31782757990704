.header {
  height: 100vh;
  position: relative;
  padding-top: 20px;
}

.dark-header {
  background-color: #0f0f0f;
  color: white;
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 20px 0 70px;
    height: fit-content;
  }
}
