.ul {
  font-family: "Lexend", sans-serif;
  font-size: 1.15rem;
  height: 100%;
}

.icons {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .list {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0 0px;
  }

  .icons {
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .list {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0 0px;
  }

  .icons {
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .list {
    display: flex;
    align-items: center;
    margin: 0px 0 0px;
  }
}
