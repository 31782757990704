@import url("https://fonts.googleapis.com/css2?family=Anton&family=Lexend&family=Nosifer&family=Rock+Salt&display=swap");

@font-face {
  font-family: LadyRadical;
  src: url("./assets/Fonts/LadyRadical.ttf");
}

body {
  color: #1d1d1d;

  scrollbar-width: thin;
  scrollbar-color: #d41e3c #f9faff;
}

.red-color {
  color: #d41e3c;
}

body::-webkit-scrollbar {
  width: 13px;
}

body::-webkit-scrollbar-track {
  background: #f9faff;
}
body::-webkit-scrollbar-thumb {
  background-color: #d41e3c;
  border-radius: 6px;
  border: 3px solid #f9faff;
}
