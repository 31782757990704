.button {
  padding: 2px 10px;
  transform: skewX(-10deg);
  border: none;
  font-size: 1.5rem;
  display: block;
  margin-right: 20px;
}

.button a {
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 600px) {
  .button {
    padding: 5px 10px;
    font-size: 1.25rem;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 1200px) {
  .button {
    padding: 2px 10px;
    font-size: 1.5rem;
    margin-right: 20px;
  }
}
