.about {
  background-color: #f9faff;
  padding: 100px 0;
  height: 100vh;
  position: relative;
}

.dark {
  background-color: #0f0f0f;
  color: #f9faff;
}

@media only screen and (max-width: 600px) {
  .about {
    height: fit-content;
  }
}
@media only screen and (min-width: 600px) {
  .about {
    height: fit-content;
  }
}

@media only screen and (min-width: 1600px) {
  .about {
    height: 100vh;
  }
}

@media only screen and (max-height: 768px) {
  .about {
    height: fit-content;
  }
}
