.card {
  background-color: white;
  margin: 20px auto;
  border-radius: 7px;
  overflow: hidden;
  width: 240px;
  position: relative;
  box-shadow: rgba(212, 30, 60, 0.4) 0px 5px, rgba(212, 30, 60, 0.3) 0px 10px,
    rgba(212, 30, 60, 0.2) 0px 15px, rgba(212, 30, 60, 0.1) 0px 20px,
    rgba(212, 30, 60, 0.05) 0px 25px;
  padding-bottom: 10px;
}

.img {
  width: 100%;
  height: 225px;
  background-color: red;
}

.text {
  padding: 20px;
  font-family: "Lexend", sans-serif;
  margin-bottom: 12px;
}

.text h3 {
  font-size: 1.2rem;
}

.link {
  font-size: 24px;
  color: #1d1d1d;
  position: absolute;
  bottom: 10px;
  left: 45%;
}

@media only screen and (max-height: 768px) {
  .card {
    margin: 10px auto;
    width: 190px;
  }

  .img {
    height: 170px;
  }
}

.dark {
  background-color: #1d1d1d;
}

.dark .link {
  color: #e0e1e6;
}

.link:hover {
  color: #d41e3c;
  cursor: pointer;
}
