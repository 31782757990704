.parent {
  margin-top: 60px;
}

.button {
  background-color: #d41e3c;
  color: white;
  font-size: 1.5rem;
  margin: auto;
}

.balance {
  font-size: 1.15rem;
  font-family: "Lexend", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
