.title,
.win-text {
  color: #1d1d1d;
}

.title {
  font-family: "Anton", sans-serif;
}

.win-text {
  font-family: "Lexend", sans-serif;
  font-size: 1.25rem;
}

.button {
  background-color: #d41e3c;
  border-radius: 7px;
  border: 0;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
}

#dark {
  background-color: #1d1d1d;
  color: white;
}

#dark .title,
#dark .win-text {
  color: white;
}

#dark .header,
#dark .footer {
  border-color: #0f0f0f;
}
