.nft-card {
  border-radius: 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  overflow: hidden;
  text-align: start;
}

.img {
  background-color: transparent;
  width: 100%;
  height: 70%;
}

.text {
  padding: 15px;
}

@media only screen and (min-width: 1200px) {
  .text {
    padding: 8px;
  }
}
