.team {
  padding: 70px 0;
  position: relative;
  height: fit-content;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .team {
    height: fit-content;
  }
}

@media only screen and (min-width: 600px) {
  .team {
    height: fit-content;
  }
}

.dark {
  background-color: #0f0f0f;
  color: white;
}
