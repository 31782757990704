.logo {
  width: 175px;
}

@media only screen and (max-width: 768px) {
  div:has(.logo) {
    text-align: center;
  }
}

@media only screen and (min-width: 600px) {
  div:has(.logo) {
    text-align: center;
  }
}
